<template>
    <div :class="classes">
        <slot></slot>
    </div>
</template>

<script>
export default {
    computed: {
        classes() {
            return {
                'content': true,
            };
        },
    },
}
</script>

<style scoped>
.content {
	/* position: relative;
    min-height: calc(var(--vh, 1vh) * 100);
    box-shadow: 0px 0px 10px grey;
    background-color: #ffffff;
    align-content: center; */
    position: relative;
    min-height: calc(var(--vh, 1vh) * 100);
    max-width: 556px;
    margin-left:auto;
    margin-right:auto;
    width: 90%;
    min-height: 100vh;
    padding-top: 15px;
    padding-bottom: 40px;
    background-color: #FFF;
    padding-left: 20px;
    padding-right: 20px;
}
</style>
