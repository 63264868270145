import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/step-1',
    name: 'Wallpaper Step 1 Page',
    component: () => import('../views/wallpaper/Step-1.vue')
  },
  {
    path: '/step-2',
    name: 'Wallpaper Step 2 Page',
    component: () => import('../views/wallpaper/Step-2.vue')
  },
  {
    path: '/step-3',
    name: 'Wallpaper Step 3 Page',
    component: () => import('../views/wallpaper/Step-3.vue')
  },
  {
    path: '/step-4',
    name: 'Wallpaper Step 4 Page',
    component: () => import('../views/wallpaper/Step-4.vue')
  },
  // {
  //   path: '/wallpaper/preview',
  //   name: 'Wallpaper Preview Page',
  //   component: () => import('../views/wallpaper/Preview.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
